.item-container {
  display: flex;
  flex-direction: row;
  gap: 3px;
  .image-blog {
    width: 50%;
  }
  .overview-item {
    display: flex;
    flex-direction: column;
    .item-title {
      font-family: Roboto;
      font-size: 24px;
    }
    .item-information {
      font-family: Roboto;
      font-size: 15px;
      font-weight: lighter;
    }
  }
}
