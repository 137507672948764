.order-detail-container {
  margin-top: 50px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;

  background-color: #ffd7fe;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  text-align: left;
  justify-content: center;

  .information {
    font-family: Roboto;
    color: #28003e;
    font-size: 24px;
    grid-column: 1 / span 7;

    .bundle {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      .bundle-tile {
        font-family: Roboto;
        color: #28003e;
        font-weight: bold;
        grid-column: 1 / span 3;
      }
      .content {
        font-weight: lighter;
        grid-column: 4 / span 4;
      }
    }
  }
  .button-paynow {
    grid-column: 8 / span 3;
    width: fit-content;
    display: flex;
    justify-content: center;
    border-radius: 15px;
    background-color: #c605c3;
    color: white;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    .paynow-text {
      padding-left: 20px;
      padding-right: 20px;
      font-family: Roboto;
      font-size: 20px;
    }
  }
}
