.container{

    display: grid;
    grid-template-columns: repeat(14,1fr);
    grid-template-rows: auto auto;
    .introduction{
        margin: 50px;
        grid-row: 1;
        grid-column: 3/ span 10;

        font-family: Roboto;
        font-size: 30px;
    }
    .calling{
        grid-row: 2;
        grid-column: 2 /span 12;
    }
}
