.reader-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  border-bottom: 2px solid white;
  position: relative;
  .image-reader {
    justify-content: left;
    display: flex;
    .avatar-image {
      height: 200px;
      left: 1px;
      object-fit: cover;
      top: 0;
      width: 200px;
    }
  }
  .information-reader {
    // flex: 2 1 400px; // Tự động co giãn
    // min-width: 300px;
    .role-experience {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .role {
      }
      .experience {
        color: #ffffffb2;
        font-family: "Roboto Mono", Helvetica;
        font-weight: 300;
        left: 199px;
      }
    }
    .name-reader {
      text-align: left;
      color: #ffffff;
      font-family: "Roboto Mono", Helvetica;
      font-size: 34px;
      font-weight: 700;
      left: 0;
      letter-spacing: 0;
      line-height: 44.2px;
      top: 0;
      width: 343px;
    }
    .feedback {
      display: flex;
      flex-direction: row;
      gap: 20px;
      align-items: center;
      .star {
        .start-image {
          height: 20px;
          left: 0;
          object-fit: cover;
          top: 0;
          width: 20px;
        }
      }
      .avarage-star {
        color: #ffffff;
        font-family: "Roboto Mono", Helvetica;
        font-size: 18px;
        display: flex;  
      }
      .num-customer {
        color: #ffffff;
        font-family: "Roboto Mono", Helvetica;
        font-size: 18px;
        font-weight: 400;
        left: 197px;
        letter-spacing: 0;
        line-height: 23.4px;
        top: 0;
        white-space: nowrap;
        width: 169px;
      }
    }
    .booking-order {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 24px;
      right: 24px;

      .price {
        display: flex;
        flex-direction: row;
        justify-content: right;
        .text-wrapper-36 {
          color: #ffffff;
          font-family: "Roboto Mono", Helvetica;
          font-size: 15px;
          font-weight: 400;
          left: 0;
          letter-spacing: 0;
          line-height: 19.5px;
          top: 6px;
          width: 15px;
        }
        .text-wrapper-37 {
          color: #ffffff;
          font-family: "Roboto Mono", Helvetica;
          font-size: 24px;
          font-weight: 700;
          left: 0;
          letter-spacing: -1.2px;
          line-height: 31.2px;
          text-align: left;
          top: 0;
          width: 155px;
        }
      }
      .bi-button {
        height: 48px;
        left: 408px;
        top: 1824px;
        width: 414px;
        display: flex;
        justify-content: right;

        .overlap-group-5 {
          background-color: #c605c3;
          border-radius: 15px;
          height: 48px;
          width: 179px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          /* Hiệu ứng mượt */
          transition: all 0.3s ease-in-out;

          &:hover {
            transform: scale(1.1); /* Phóng to 10% */
          }
          &:active {
            transform: scale(0.8); /* Phóng to 10% */
          }

          .text-wrapper-34 {
            color: #ffffff;
            font-family: "Roboto", Helvetica;
            font-size: 20px;
            font-weight: 700;
            transition: color 0.3s ease-in-out;
          }
        }
      }
    }
    .detail-content {
      text-align: left;
      color: #ffffff;
      font-family: "Roboto", Helvetica;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: 0;
      line-height: 27px;
      flex-wrap: wrap;
    }
  }
}
@media (min-width: 1200px) {
  .reader-container {
    max-width: 1100px;
    margin: 0 auto;
  }

  .reader-container .image-reader,
  .reader-container .information-reader,
  .reader-container .role-experience,
  .reader-container .name-reader,
  .reader-container .feedback,
  .reader-container .booking-order,
  .reader-container .detail-content {
    max-width: 100%;
    // margin: 0 auto;
  }
}
