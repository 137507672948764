.payment-container {
  display: flex;
  justify-content: space-between;
  margin: 20px;
  .total-payment {
    font-family: Roboto;
    font-size: 25px;
  }
  .price {
    font-family: Roboto;
    font-size: 25px;
  }
  .button-paynow {
    border-radius: 15px;
    background-color: #c605c3;
    color: white;
    cursor: pointer;
    .paynow-text {
      padding-left: 20px;
      padding-right: 20px;
      font-family: Roboto;
      font-size: 20px;
    }
  }
}
