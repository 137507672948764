.footer-container {
  background-color: #250836;

  margin-top: 20px;
  border-top: 1px solid #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
height: 200px;
  .logo {
    margin-top: 20px;
    margin-left: 200px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    .logo-image {
      width: 270px;
      height: 100px;
    }
    .hash-tag {
      font-family: Helvetica;
      font-size: 20px;
      text-align: left;
    }
  }
  .general-information {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    text-align: left;
    .title {
      font-family: "Roboto";
      font-size: 20px;
    }
    .content-group {
      .content {
        opacity: 0.8;
        text-decoration: none; // Loại bỏ gạch chân
        color: #ffffff;
        font-family: "Roboto";

        font-size: 20px;
      }
    }
  }
  .connection {
    margin-top: 20px;
    margin-right: 200px;
    .connection-container {
      text-align: left;
      display: flex;
      flex-direction: column;
      gap: 30px;
      .title {
        font-family: "Roboto";
        font-size: 20px;
      }
      .information-container {
        padding-bottom: 10px;
        display: flex;
        flex-direction: column;
        .phone-number,.email {
          font-family: "Roboto";
          font-size: 20px;
          opacity: 0.8;
        }
        .icon {
          display: flex;
          flex-direction: row;
          gap: 50px;
        }
      }
    }
  }
}
