.blog-detail-container {
    .content-container {
      display: grid;
      grid-template-columns: repeat(14, 1fr);
      gap: 20px; 
    }
  
    .content-container > :first-child { 
      grid-column: 2 / span 7; 
    }
  
    .content-container > :last-child { 
      grid-column: 10 / span 4; 
    }
  }
  