.blog-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  // width: ;
  
  .introduction {
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    align-items: center;
    .introduction-image {
      .image {
        width: 100%;
        height: 650px;
      }
    }
    .text-container {
      position: absolute;
      .title {
        font-size: 64px;
        font-family: "Helvetica-Bold", Helvetica;
        font-weight: bold;
      }
      .description {
        font-size: 20px;
        font-family: "Roboto", Helvetica;
      }
    }
  }
  .blog-list {
  }
}
