.blog-container {
  z-index: 10;
  width: 416px;
  height: 530px;
  background-color: #14001f;
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .content-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .blog-image {
      width: 388px;
      height: 272px;
      .image {
        width: 388px;
        height: 272px;
        border-radius: 15px;
      }
    }
    .text {
      margin: 20px;
      text-align: left;
      .blog-name {
        font-size: 24px;
        font-family: "Roboto", Helvetica;
        font-weight: bold;
      }
      .blog-content {
        font-size: 20px;
        font-family: "Roboto", Helvetica;
      }
    }
    
    .btn-detail {
      width: 165px;
      height: 45px;
      background-color: #28003e;
      border-radius: 15px;
      border: 2px solid #c605c3;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out,
        transform 0.3s ease-out;
      cursor: pointer;
      user-select: none;

      &:hover {
        transform: scale(1.1);
      }
      &:active {
        transform: scale(0.8);
      }

      .detail-text {
        font-size: 20px;
        font-family: "Roboto", Helvetica;
        text-decoration: none;
        color: #ffffff;

      }
    }
  }
}
