.container{
    display: grid;
    grid-template-columns: repeat(14,1fr);
    grid-template-rows: auto auto;
    .introduction{
        grid-row: 1;
        grid-column: 3 / span 10;
        font-family: Roboto;
        font-size: 24px;

    }
    .tarot-reader{
        margin-top: 50px;
        grid-row: 2;
        grid-column: 3 /span 8;

    }
    .watch-now{
        grid-row: 3;
        grid-column: 3/ span 10;

    }
}