.introduction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .introduction-img {
    position: relative;
    height: 317px;
  }
  .introduction-text {
    font-family: "Roboto Mono", Helvetica;
    position: absolute;
    font-size: 64px;
    font-weight: bold;
  }
}
