.schedule-detail-container {
  display: grid;
  grid-template-columns: repeat(14,1fr);
  gap: 20px;

  .week-selector {
    grid-column: 1 / span 3;
    .schedule-option {
      display: flex;
      flex-direction: column;
      height: fit-content;
      font-family: Roboto;
      font-size: 14px;
      border-radius: 15px;
      border: 1px solid #c605c3;
      background: #28003e;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: background 0.3s ease-in-out;
      cursor: pointer;
      text-align: center;
      color: white;

      &:hover {
        background: #3b0057;
      }
    }
    .dropdown {
      background: #28003e;
      border: 1px solid #c605c3;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: 10;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 8px;

      max-height: 200px;
      overflow-y: auto;

      .option {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease-in-out;
        color: white;

        &:hover {
          background: #ff91fd;
          color: black;
        }
      }
    }
    .selected-week {
      font-size: 16px;
    }
  }
  .table-schedule {
    grid-column: 4 / span 12;
  }
  .payment{
    grid-column: 1 / span 10;
    border: 1px solid #C605C3;
    max-height: 0;
    opacity: 0;

    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-out,
    transform 0.5s ease-in-out;
    overflow: hidden;

  }
  .payment.show{
    max-height: 100%;
    opacity: 1;
  }
}





.slot-check-container {
  width: 105px;
  height: 40px;
  background-color: #ffd7fe; // Màu nền cho slot-check-container
  align-items: center;
  text-align: center;
  vertical-align: middle;
  justify-content: center;
  border: 1px solid black;

  font-family: Roboto;
  font-size: 16px;
  color: #28003e;
}


