.div {

  .introduction {

    .image-container {
      padding-top: 20px;

      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .tarot-image {
        width: 100%;
        height: auto;
        display: block;
      }

      .overlay-text {
        position: absolute;
        color: white;
        font-size: 24px;
        font-weight: bold;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      }
    }
  }
  .sort-by {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    gap: 20px;

    .sort-text {
      color: #ffffff;
      font-family: "Roboto", Helvetica;
      font-size: 20px;
      font-weight: 700;
      height: 38px;
      letter-spacing: 0;
      line-height: 30px;
      text-align: right;
      width: 200px;
    }

    .sort-option {
      border: 2px solid #ffffff;
      border-radius: 10px;
      height: 39px;
      width: 200px; // Độ rộng cố định
      cursor: pointer;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;

      .sort-value {
        color: #ffffff;
        font-family: "Roboto", Helvetica;
        font-size: 20px;
        font-weight: 400;
        height: 38px;
        line-height: 30px;
        text-align: center;
      }
    }

    .dropdown-list-sort {
      position: relative;
      top: 100%;
      left: 0;
      background: rgba(255, 255, 255, 0.9);
      border-radius: 10px;
      border: 2px solid #ffffff;
      box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
      max-height: 0;
      opacity: 0;
      transform: translateY(-10px);
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out,
        transform 0.3s ease-out;
      overflow: hidden;
      z-index: 10;

      &.open {
        max-height: 250px;
        opacity: 1;
        overflow-y: auto;
        transform: translateY(0);
      }
      .sort-bar-value {
        padding: 10px;
        cursor: pointer;
        color: #ff000090;
        font-size: 18px;
        font-family: "Roboto", Helvetica;
        font-weight: 400;
        text-align: center;
        transition: background 0.2s ease-in-out;
        background: rgb(207, 185, 185);

        &:hover {
          background: rgba(196, 73, 202, 0.778);
        }
      }
    }
  }

  .body {
    display: flex;
    margin-top: 50px;
    gap: 50px;
    max-width: calc(100% - 200px);
    margin-left: 100px;
    .reader-list {
      flex: 4;
      align-items: center;
      position: relative;
      display: flex;
      flex-direction: column;
      align-self: center;
      .pagination {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-top: 20px;
      }

      .pagination button {
        background-color: #c605c3;
        color: white;
        border: none;
        padding: 10px 15px;
        border-radius: 5px;
        cursor: pointer;
        transition: 0.3s;
      }

      .pagination button:disabled {
        background-color: #666;
        cursor: not-allowed;
      }

      .pagination span {
        font-size: 16px;
        color: white;
      }

      .watchmore-reader {
        background-color: #c605c3;
        border-radius: 15px;
        height: 48px;
        position: relative;
        width: 412px;
        margin-top: 50px;
        display: flex;
        align-self: center;
        justify-content: center;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
        &:active {
          transform: scale(0.5);
        }

        .watchmore-text {
          color: #ffffff;
          font-family: "Roboto", Helvetica;
          font-size: 20px;
          font-weight: 700;
          text-align: center;
          display: flex;
          align-self: center;
        }
      }
    }
    .filter {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 20px;
      .price {
        .price-tile {
          color: #ffffff;
          font-family: "Roboto", Helvetica;
          font-size: 20px;
          font-weight: 700;
          left: 6px;
          letter-spacing: 0;
          line-height: 30px;
          top: 0;
          width: 126px;
        }
      }
      .price-range {
        display: flex;
        .price-box {
          border: 2px solid;
          border-color: #ffffff;
          border-radius: 10px;
          height: 32px;
          position: relative;
          align-items: center;
          width: 92px;
          .vecter {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }

          .price-value {
            color: #ffffff;
            font-family: "Roboto", Helvetica;
            font-size: 14px;
            font-weight: 400;
            height: 31px;
            left: -2px;
            letter-spacing: 0;
            line-height: 21px;
            text-align: center;
            align-items: center;
            top: -2px;
            width: 92px;
          }
        }
      }
      .slide-container {
      }
      .filter-star {
        display: flex;
        flex-direction: column;
        justify-items: left;
        text-align: left;
        .evaluate-tile {
          color: #ffffff;
          font-family: "Roboto", Helvetica;
          font-size: 20px;
          font-weight: 700;
          left: 0;
          letter-spacing: 0;
          line-height: 30px;
          top: 0;
          width: 94px;
        }
        .start-value {
          display: flex;
          flex-direction: row;
          gap: 10px;
          .star-vote {
            height: 15px;
            left: 19px;
            object-fit: cover;
            top: 0;
            width: 15px;
          }
        }
      }
    }
  }
}
