.home {
  background-color: #250836;
  display: flex;
  flex-direction: column;
  display: flex;
  min-height: 100vh;
  .content {
    flex-grow: 1; // Chỉ phần content mở rộng
    background-color: #250836; // Màu ví dụ
  }
}

.home .p {
  color: #ffffff;
  font-family: "Roboto", Helvetica;
  font-size: 20px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 30px;
  opacity: 0.8;
  position: absolute;
  top: 375px;
  width: 391px;
}
