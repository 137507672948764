.schedule-container {
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  gap: 10px;
  .selected-bundle {
    display: flex;
    flex-direction: column;
    gap: 15px;
    width: 250px;
    grid-column: 2 / span 2;
    .bundle-option,
    .time-option {
      cursor: pointer;
      padding: 12px;
      background: #28003e;
      border: 1px solid #c605c3;
      border-radius: 15px;
      position: relative;
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      transition: background 0.3s ease-in-out;

      &:hover {
        background: #631061;
      }
    }
    .dropdown {
      position: absolute;
      background: #28003e;
      border: 1px solid #c605c3;
      width: 100%;
      top: 100%;
      left: 0;
      z-index: 10;
      box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
      border-radius: 8px;
      overflow: hidden;

      .option {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        cursor: pointer;
        transition: background 0.3s ease-in-out;

        &:hover {
          background: #ff91fd;
        }
      }
    }
  }
  .booking-schedule {
    grid-column: 4 / span 10;
    position: relative;
    .schedule-option-container {
      display: grid;
      grid-template-columns: repeat(8,1fr);
      .schedule-option {
        grid-column: 1 span 4;
        height: fit-content;
        font-family: Roboto;
        font-size: 16px;
        border-radius: 15px;
        border: 1px solid #c605c3;
        background: #28003e;
        padding: 12px;
        transition: background 0.3s ease-in-out;
        cursor: pointer;
      }
    }

    .button-booking {
      &:hover {
        background: #631061;
      }
    }
    .schedule {
      margin-top: 20px;
      margin-bottom: 20px;

      max-height: 0;
      opacity: 0;
      transform: translateY(50px);
      transition: max-height 0.5s ease-in-out, opacity 0.5s ease-out,
        transform 0.5s ease-in-out;
      overflow: hidden;
    }

    .schedule.show {
      max-height: 500px;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
