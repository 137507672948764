.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 50px;
  .video-container {
    display: flex;
    gap: 10px;
    .video-screan {
      width: 450px;
      height: 300px;
      background: black;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      transition: all 0.3s ease;
    }
    .no-sharing,
    .sharing {
      display: flex;
      gap: 10px;
      opacity: 0;
      transform: scale(0.9);
      animation: fadeIn 0.5s ease-in-out forwards;
    }

    .no-sharing {
      display: flex;
      gap: 10px;
    }

    .sharing {
      display: grid;
      grid-template-columns: 2fr 1fr; // 2/3 và 1/3
      gap: 10px;
      transition: all 0.5s ease-in-out; // Hiệu ứng chuyển đổi mượt mà

      .screen-share {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        animation: fadeIn 0.5s ease-in-out;
      }

      .camera-user-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 10px;
        animation: slideIn 0.5s ease-in-out;
      }
    }

    // Animation xuất hiện mượt
    @keyframes fadeIn {
      from {
        opacity: 0;
        transform: scale(0.9);
      }
      to {
        opacity: 1;
        transform: scale(1);
      }
    }

    // Animation trượt vào
    @keyframes slideIn {
      from {
        opacity: 0;
        transform: translateX(50px);
      }
      to {
        opacity: 1;
        transform: translateX(0);
      }
    }
  }
  .control {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; // Khoảng cách giữa các nút
    margin-top: 20px;
    .control-button {
    }
    .turn-off {
      background-color: red;
    }
    button {
      width: 50px;
      height: 50px;
      border: none;
      border-radius: 50%;
      background-color: #333;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: background 0.3s ease, transform 0.2s ease;
      cursor: pointer;

      &:hover {
        transform: scale(1.1);
      }

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .end-call {
      background-color: red;

      &:hover {
        background-color: darkred;
      }
    }
  }
}
