.newest-containter {
    margin-top: 50px;
    .title {
      font-family: Roboto;
      font-size: 32px;
      font-weight: bold;
      text-align: left;
      position: relative; /* Để dùng ::after */
  
      &::after {
        content: "";
        display: block;
        width: 75%; /* 3/4 chiều rộng của phần tử cha */
        height: 1px; /* Độ dày của gạch dưới */
        background-color: rgb(255, 255, 255); /* Màu của gạch dưới */
        margin-top: 5px; /* Khoảng cách giữa chữ và gạch dưới */
      }
    }
    .item-container{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
  }
  