.div-card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50px;
  margin-left: 150px;
  margin-right: 150px;
  padding-bottom: 100px;

  .body {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 20px;

    .title {
      font-size: 35px;
      font-weight: 700;
      // font-family: "Charmonman", Helvetica;
      color: #ffffff;
      margin-bottom: 20px; // Khoảng cách giữa title và các phần tử bên dưới
    }

    .card-img {
      display: flex;
      justify-content: center;
      align-items: center; // Căn giữa theo chiều dọc
      gap: 10px; // Khoảng cách giữa các phần tử (hình ảnh và chữ)

      .wing-1,
      .wing-2 {
        width: 118px; // Kích thước hình ảnh
        height: 43px;
      }

      .three-la {
        color: #ef9ffe;
        font-family: "Charmonman", Helvetica;
        font-size: 30px;
        font-weight: 700;
        margin: 0; // Loại bỏ margin mặc định
      }
    }
    .card-text {
      color: #ef9ffe;
      margin: 0;
    }
    .option-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;

      .title-option {
        width: 939px;
        position: relative;

        .option-bar,
        .option-bar-value {
          width: 100%;
          height: 93px;
          background-color: transparent;
          border: 2px solid rgb(139, 12, 136);
          border-radius: 25px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;
          user-select: none;

          .inner-bar {
            width: calc(100% - 16px);
            height: calc(100% - 16px);
            background-color: #ffffff;
            border-radius: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            user-select: none;

            .option-text {
              user-select: none;
              color: #28003e;
              font-family: "Roboto", Helvetica;
              font-size: 30px;
              font-weight: 500;
              text-align: center;
              width: 90%;
            }
          }
        }

        // Danh sách chủ đề (ẩn khi openTopic = false)
        .dropdown-list {
          position: absolute;
          top: 100%;
          left: 0;
          width: 100%;
          max-height: 0;
          overflow: hidden;
          background-color: #ffffff;
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          z-index: 10;
          border-radius: 25px;
          opacity: 0;
          transform: translateY(-10px); // Ban đầu lệch lên 10px
          transition: transform 0.3s ease-out, opacity 0.3s ease-out,
            max-height 0.3s ease-out;

          &.open {
            max-height: 250px;
            opacity: 1;
            transform: translateY(0); // Kéo xuống dần
            overflow-y: auto;
          }
          // Tùy chỉnh thanh cuộn
          &::-webkit-scrollbar {
            width: 8px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgb(139, 12, 136);
            border-radius: 10px;
          }

          &::-webkit-scrollbar-track {
            background: #f0f0f0;
            border-radius: 10px;
          }
        }

        .option-bar-value {
          height: 70px;
          border-bottom: 1px solid rgb(139, 12, 136);
          border-radius: 0px;
          background-color: #ffffff;
          transition: background-color 0.3s;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          position: relative;

          &:last-child {
            border-bottom: none;
          }

          &:hover {
            background-color: rgba(72, 18, 71, 0.1);
          }
        }
      }

      .shuffle-btn {
        display: flex;
        justify-content: center; // Căn giữa nút ngang
        align-items: center; // Căn giữa nút dọc
        width: 200px; // Độ rộng nút
        height: 60px; // Chiều cao nút

        border: 2px solid rgb(139, 12, 136);
        border: none; // Bỏ viền nút
        border-radius: 30px; // Bo tròn góc
        cursor: pointer; // Thay đổi con trỏ khi hover
        transition: all 0.3s ease; // Hiệu ứng chuyển đổi khi hover

        &:hover {
          transform: scale(1.1); // Phóng to nhẹ khi hover
        }

        .shuffle-btn-background {
          display: flex;
          width: 100%;
          height: 100%;
          transition: background-color 0.3s ease;
          background-color: transparent; // Thanh ngoài không có màu nền
          border: 2px solid rgb(139, 12, 136); // Viền màu theo yêu cầu
          border-radius: 25px; // Bo tròn 4 góc
          justify-content: center; // Căn giữa nội dung ngang
          align-items: center; // Căn giữa nội dung dọc
          position: relative; // Hiệu ứng chuyển đổi màu nền

          .shuffle-inner {
            width: calc(100% - 4px); // Chiều rộng nhỏ hơn thanh ngoài
            height: calc(100% - 4px); // Chiều cao nhỏ hơn thanh ngoài
            background: linear-gradient(
              to bottom,
              rgb(139, 12, 136),
              rgb(249.05, 82.19, 246.27) 25%,
              rgb(255, 145.14, 253.17) 50%,
              rgb(249, 82, 246) 75%,
              rgb(138.55, 12.35, 136.45) 100%
            );

            // Màu nền trắng
            border-radius: 25px; // Bo tròn để nằm trong thanh ngoài
            display: flex;
            justify-content: center; // Căn giữa chữ ngang
            align-items: center;

            .shuffle-text {
              color: #ffffff;
              font-family: "Roboto", Helvetica;
              font-size: 30px;
              font-weight: 500;
              left: 0;
              letter-spacing: 0;
              line-height: 39px;
              text-align: center;
              top: 19px;
              width: 743px;
            }
          }
        }
      }
    }
    .result-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      .shuffle-all-card {
        position: relative;
        .card-container {
          padding-top: 40px;
          display: flex;
          flex-wrap: wrap; /* Cho phép các thẻ xuống dòng nếu cần */
          justify-content: center; /* Căn giữa các thẻ bài */
          row-gap: 40px;
          width: 100%;
        }

        .card-button {
          box-sizing: border-box;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 180px; /* Tăng chiều cao thẻ bài */
          width: 120px; /* Tăng chiều rộng thẻ bài */
          transition: transform 1s ease, scale 0.3s ease;
          margin-left: -90px; /* Để lá bài đè lên nhau, có thể điều chỉnh theo ý muốn */
          z-index: 1; /* Đảm bảo các thẻ nằm trên nhau */
          flex-shrink: 0; /* Prevent shrinking due to margin */

          &:hover {
            transform: translateY(-10px) scale(1.05);
            border: 2px solid #03e629;
          }
          &.active {
            transform: translateY(-20px) scale(1.05);
            border: 2px solid #f7e703;
          }

          .card-select {
            width: 100%;
            height: auto;
            object-fit: cover;
          }
        }
      }
      .select-result {
        display: flex;
        flex-direction: row;
        gap: 50px;
        .card-container {
          .card {
            transform-style: preserve-3d;
            transition: transform 0.8s;
            position: relative;

            &.flipped {
              transform: rotateY(180deg);
            }

            .card-front,
            .card-back {
              backface-visibility: hidden;
            }

            .card-back {
              transform: rotateY(180deg);
            }
          }
        }
      }
      .meaning-btn {
        cursor: pointer;
        .meaning-background {
          background-color: #28003e;
          border: 1px solid;
          border-color: #c605c3;
          border-radius: 12px;
          height: 62px;
          position: relative;
          width: 222px;
          transition: transform 0.3s ease, scale 0.3s ease;

          &:hover {
            transform: scale(1.2);
          }
          &:active {
            transform: scale(0.9);
          }
          .meaning-word {
            color: #ffffff;
            font-family: "Roboto", Helvetica;
            font-size: 24px;
            font-weight: 900;
            left: 25px;
            letter-spacing: 0;
            line-height: 36px;
            position: absolute;
            text-align: center;
            top: 11px;
            white-space: nowrap;
            width: 170px;
            user-select: none;
          }
        }
      }
      .detail-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .select-result {
          display: flex;
          flex-direction: column;
          .card-result {
            display: flex;
            flex-direction: row;
            gap: 50px;
            .result-box {
              position: relative;
              transform-style: preserve-3d;

              transition: transform 0.6s ease-in-out;

              .result-front,
              .result-back {
                backface-visibility: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: transform 0.6s ease-in-out;
                border-radius: 20px;
                .element-image {
                  height: 308px;
                  width: 208px;
                  border-radius: 10%;
                }
              }
              .result-front {
                opacity: 1;
                transform: rotateY(0deg);
              }
              .result-back {
                opacity: 0;
                transform: rotateY(180deg);
                transition-delay: 0.3s;
              }
              &.flipped {
                transform: rotateY(180deg);
                .result-front {
                  opacity: 1;
                }

                .result-back {
                  opacity: 1;
                }
              }
            }
          }
        }

        .meaning-result {
          display: flex;
          flex-direction: column;
          gap: 50px;
          .card-result {
            display: flex;
            flex-direction: row;
            gap: 20px;
            .element-image-detail {
              width: 208px;
              height: 308px;
              aspect-ratio: 6 / 4; /* Tỷ lệ 6:4 */
              object-fit: cover; /* Đảm bảo hình ảnh không bị méo */
              border-radius: 10%;
            }

            .meaning-text {
              text-align: left;
            }
          }
        }
        .sumarize {
          display: flex;
          gap: 20px;
          .card-sumarize {
            display: flex;
            gap: -20px;
            margin-left: -50px;
            padding-right: 100px;

            .card-left,
            .card-middle,
            .card-right {
              width: 208px;
              height: 308px;
              aspect-ratio: 6 / 4; /* Tỷ lệ 6:4 */
              object-fit: cover; /* Đảm bảo hình ảnh không bị méo */
            }

            .card-left {
              transform: rotate(-15deg) translateY(30px);
            }
            .card-middle {
              z-index: 1;
            }
            .card-right {
              transform: rotate(15deg) translateY(30px);
            }
          }
          .loading-spinner {
            width: 50px;
            height: 50px;
            border: 5px solid rgba(255, 255, 255, 0.3);
            border-top-color: #fff;
            border-radius: 50%;
            margin: 20px auto;
          }
          .text-sumarize {
            display: flex;
            text-align: left;
            .sumarize-content {
              font-size: 23px;
            }
          }
        }
      }
    }
  }
}
