.reader-profile-container {
    display: grid;
    grid-template-columns: repeat(14, 1fr); // Chia thành 14 cột đều nhau
    gap: 16px; // Tùy chọn, điều chỉnh khoảng cách giữa các cột nếu cần
  }
  
  .reader-profile-container > :first-child {
    grid-column: 2 / span 7; //
  }
  
  .reader-profile-container > :last-child {
    grid-column: 10 / span 4; // Bắt đầu từ cột 10, chiếm 3 cột (cột 10 đến cột 12)
  }
  