.profile-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 16px;
  text-align: left;
  .avatar-profile {
    height: 200px;
    width: 200px;
  }
  .profile-content {
    .name {
      font-family: Roboto Mono;
      font-size: 20px;
      font-weight: normal;
    }
    .expert {
      font-family: Roboto Mono;
      font-size: 30px;
      font-weight: bold;
    }
    .experience {
      font-family: Roboto Mono;
      font-size: 15px;
      font-weight: lighter;
      opacity: 0.5;
    }
    .profile {
      font-family: Roboto;
      font-size: 18px;
      font-weight: normal;
    }
  }
}

.avatar-profile {
  grid-column: 1 / 3;
  width: 100%;
}

.profile-content {
  grid-column: 3 / 8;
}
