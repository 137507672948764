.header-container {
  margin-top: 25px;
  width: 100vw;
  display: flex;
  justify-content: space-between; 
  padding: 0 50px; 
  height: 60px;
  .logo {
    display: flex;

    .logo-image {
      width: 118px;
      height: 44px;
    }
  }

  .navigater {
    display: flex;
    flex-direction: row;
    gap: 20px; // Khoảng cách giữa các link
  
    .nav-item {
      text-decoration: none; // Loại bỏ gạch chân
      color: #ffffff; // Màu chữ mặc định
      font-weight: 500;
      font-size: 18px;
      display: flex;
      // justify-content: center;
      font-family: Helvetica;
  
      &:hover {
        color: #c605c3;
      }
    }
  }
  
  .authenticate {
    .user-info {
      position: relative;
      display: flex;
      align-items: center;
      gap: 10px;
      cursor: pointer;
  
      .user-name {
        background-color: #28003E;
        font-size: 18px;
        color: #ffffff;
        font-weight: 500;
        padding: 10px 15px;
        border: 2px solid #C605C3;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px; // Đảm bảo ô không bị quá nhỏ
        text-align: center;
        transition: background-color 0.3s ease, border-color 0.3s ease;
        &:hover {
          background-color: #C605C3;
          border-color: #ffffff;
        }
      }
  
      .dropdown-menu {
        position: absolute;
        top: 40px;
        right: 0;
        width: 150px;
        background-color: #28003E; // Màu của authen-background
        border: 2px solid #C605C3;
        border-radius: 8px;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
        z-index: 10;
        display: flex;
        flex-direction: column;
  
        .dropdown-item {
          padding: 12px;
          color: #ffffff;
          text-align: center;
          font-size: 16px;
          cursor: pointer;
          background: none;
          border: none;
          width: 100%;
          &:hover {
            background-color: #C605C3;
          }
        }
      }
    }
    .auth-links {
      display: flex;
      flex-direction: row;
      gap: 50px;
      
      .authen-background {
        width: 131px;
        height: 45px;
        border-radius: 12px;
        background-color: #28003E;
        border: 2px solid #C605C3;
        display: flex;
        align-items: center;
        justify-content: center; // Canh giữa chữ trong button

        .authen-text {
          text-decoration: none;
          color: #ffffff;
          font-size: 20px;
        }
      }
    }
  }
}
