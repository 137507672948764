.container {
  background-color: #ffffff;
  border-radius: 20px;
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  align-items: center;
  justify-content: center;

  .name,
  .time {
    padding: 20px;
    color: #000000;
    display: flex;
    flex-direction: column;
    .title {
      font-weight: bold;
    }
    .content {
    }
  }
  .name {
    text-align: left;
    grid-column: 2 / span 4;
  }
  .time {
    grid-column: 6 / span 2;
  }
  .btn-view {
    background-color: #c605c3;
    grid-column: 9 / span 10;
    display: flex;
    width: max-content;
    border-radius: 15px;

    cursor: pointer;
    .text {
        padding: 10px;
        padding-right: 50px;
        padding-left: 50px;

        font-family: Roboto;
        font-size: 24px;
    }
  }
}
