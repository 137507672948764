.order-container {
  display: grid;
  grid-template-columns: repeat(14, 1fr);
  grid-template-rows: auto auto;
  gap: 16px;
  .title {
    font-family: Roboto Mono;
    font-size: 36px;
    font-weight: bold;
    grid-column: 3 / span 8;
    grid-row: 1;
    text-align: left;
  }
  .profile {
    grid-column: 3 / span 8;
    grid-row: 2;
  }

  .sumarize-order {
    font-family: Roboto Mono;
    font-size: 36px;
    font-weight: bold;
    text-align: left;
    grid-column: 3 / span 10;
    grid-row: 3;

    .order-detail-container {
      height: 200px;
      background-color: #ffd7fe;
      display: grid;
      grid-template-columns: repeat(10, 1fr);
      align-content: center; /* Căn giữa theo chiều dọc */
      justify-content: center;
      .information {
        margin-left: 50px;
        font-family: Roboto;
        color: #28003e;
        font-size: 24px;
        grid-column: 1 / span 5;

        .bundle {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          .bundle-tile {
            grid-column: 1 / span 2;
          }
          .content {
            font-weight: lighter;
            grid-column: 3 / span 3;
          }
        }
      }
      .payment {
        font-family: Roboto;
        color: #28003e;
        font-size: 24px;
        grid-column: 6 / span 3;
        .payment-title {
        }
        .payment-method {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          .momoPay,
          .zaloPay {
            height: 84px;
            width: 84px;
            cursor: pointer;
            border: 3px solid transparent; // Mặc định không có viền
            border-radius: 10px;

          }
          .show{
            border-color: #C605C3;
          }
          .zaloPay {
            grid-column: 1;
          }
          .momoPay {
            grid-column: 2;
          }
        }
      }
      .button-paynow {
        height: fit-content;
        display: flex;
        justify-content: center;
        border-radius: 15px;
        background-color: #c605c3;
        color: white;
        cursor: pointer;
        .paynow-text {
          padding-left: 20px;
          padding-right: 20px;
          font-family: Roboto;
          font-size: 20px;
        }
      }
    }
  }
}
