@keyframes pop {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.2); // Phình to ở giữa animation
    }
    100% {
      transform: scale(1); // Quay về kích thước ban đầu
    }
  }
  
  .checkbox {
    display: inline-block; /* Giữ đúng kích thước mà không làm vỡ bảng */
    width: 70px;
    height: 25px;
    background-color: #ffffff;
    border-radius: 9px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  
  .selected {
    background-color: red;
    animation: pop 0.2s ease-in-out; 
  }
  
  .isCheck {
    background-color: #ff91fd;
    animation: pop 0.2s ease-in-out; 
  }
  