.comment-container {
    margin-left: 100px;
    margin-right: 100px;
  .comment-input {
    .input-container {
      .avatar-input {
      }
      .input-text {
      }
    }
    .send-btn {
      height: 51px;
      width: 141px;
      background-color: #28003e;
      border: 1px solid #c605c3;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .send-text {
        font-size: 16px;
      }
    }
  }
}
