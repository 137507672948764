.detail-container {
  margin-top: 50px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  gap: 20px;

  .profile-container {
    display: flex;
    justify-content: space-between;
    .avatar-profile {
      width: 30%; // Avatar chiếm khoảng 1/3 chiều rộng
      margin-right: 100px; // Khoảng cách 50px giữa các phần

      .avatar {
        .avatar-image {
          width: 200px;
          height: 200px;
        }
      }

      .reader-name {
      }

      .feedback {
        display: flex;
        gap: 20px;

        .star-containter {
          display: flex;
          flex-direction: row;
          gap: 5px;

          .star-image {
          }
        }

        .avarage-star,
        .num-customer {
          display: flex;
          align-items: center;
        }
      }
    }

    .detail-profile {
      width: 70%; // Phần còn lại chiếm 2/3 chiều rộng
      display: flex;
      flex-direction: column; // Bố cục dọc để giữ các phần nội dung căn chỉnh đúng
      gap: 10px;
      margin-left: 100px; // Khoảng cách 50px từ phần avatar-profile

      .content-container {
        display: flex;
        flex-direction: row; // Mặc định là hàng ngang
        gap: 10px;
        text-align: left;

        .title {
          font-weight: bold;
          min-width: 150px; // Đảm bảo tiêu đề có kích thước cố định
        }

        .content {
          flex: 1; // Nội dung sẽ lấp đầy phần còn lại
        }

        &.wrap {
          flex-direction: column !important;
          align-items: flex-start; // Căn trái để không bị giãn giữa
        }
      }
    }
  }
  .action-container {
    display: flex;
    flex-direction: row;
    gap: 45px;
    justify-content: center;
    .button-action {
      background-color: #07003e;
      width: 208px;
      height: 76px;
      border: 2px solid #ea0070;
      border-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      transition: max-height 0.3s ease-out, opacity 0.3s ease-out,
        transform 0.3s ease-out;

      &:hover {
        transform: scale(1.08);
      }
      &:active {
        transform: scale(0.8);
      }

      .button-text {
        font-family: "Roboto";
        color: #ffffff;
        text-decoration: none;
        font-size: 30px;
      }
    }
  }
  .comment-container {
  }
}
