.introduction {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: relative;


  .title {
    font-family: Roboto Mono;
    font-weight: bold;
    font-size: 48px;
  }

  .date {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .date-time {
    }

    .role {
      .role-name {
      }
    }
  }


  .blog-detail-image {
    width:100%;
  }
  
}
.content-container{
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: left;
  margin-top: 20px;
    .content{

    }
}
